import React, { useState } from "react";
import { FooterBottomData } from "@/data";
import { Col, Row } from "react-bootstrap";
import axios from "axios"

const ContactOne = (props) => {
    let data = props.data;

    const [formError, setFormError] = useState("")
    const [emailResponse, setEmailResponse] = useState("")
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);


    function contactUs() {
        let fullName = document.getElementById("fullName").value
        let email = document.getElementById("email").value
        let txtArea = document.getElementById("txtArea").value

        if (fullName.length > 4) {
            if (validateEmail(email)) {
                if (txtArea.length >= 10) {
                    let payload = {
                        fullName: fullName,
                        email: email,
                        message: txtArea,
                    }

                    setLoading(true)

                    axios.post(`https://admin.topaper.gr/wp-json/myplugin/v1/contactEmail`, payload)
                        .then(function (response) {
                            // handle success
                            setEmailFailed(false)
                            setEmailSent(true)
                            setEmailResponse(response.data)
                            setLoading(false)

                        })
                        .catch(function (error) {
                            // handle error
                            setEmailFailed(true)
                            setEmailResponse(data.responses.failed)
                            setLoading(false)
                        })

                    setFormError("")
                } else {
                    setFormError("Το μήνυμά σας θα πρέπει να ξεπερνά τους 9 χαρακτήρες")
                }
            } else {
                setFormError("Το email σας δεν είναι έγκυρο")
            }

        } else {
            if (fullName.length === 0) {
                setFormError("Συμπληρώστε το ονοματεπώνυμό σας")
            } else {
                setFormError("To ονοματεπώνυμο θα πρέπει να ξεπερνά τους 4 χαρακτήρες")
            }
        }
    }

    function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <section className="contact-section sec-pad">
            <div className="thm-container">
                <Row>
                    {emailFailed && (
                        <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                    )}

                    {!emailSent
                        ?
                        <Col lg={6} style={{ padding: "0px!important", alignItems: "center" }}>
                            <div className="contact-form-content">
                                <div className={`title text-center`}>
                                    <span>Επικοινωνία</span>
                                    <h2>Αποστολή Μηνύματος</h2>
                                </div>

                                <div className="contact-form">
                                    <input type="text" name="fullName" id="fullName" placeholder="Ονοματεπώνυμο" />
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                    />
                                    <textarea
                                        name="txtArea"
                                        id="txtArea"
                                        placeholder="Το μήνυμα σας"
                                    ></textarea>

                                    <button onClick={contactUs} className="thm-btn yellow-bg">
                                        {!loading ?
                                            <div>Αποστολή</div>
                                            :
                                            <div>Αναμένεται</div>
                                        }   
                                    </button>

                                    <div className="form-result">
                                        {formError !== "" && (
                                            <span className="w-full text-center block pt-4" style={{ color: "red" }}>{formError}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        :
                        <Col lg={6} style={{ padding: "35px!important", alignItems: "center", display:"flex" }}>
                            <div className="email-response py-12" style={{ textAlign: "center",color: "green"}} dangerouslySetInnerHTML={{ __html: emailResponse }} />
                        </Col>
                    }
                    <Col lg={6} style={{ padding: "0px!important" }}>
                        <div className="contact-info text-center h-full">
                            <div className={`title text-center`}>
                                <span>{data.subtitle}</span>
                                <h2>{data.title}</h2>
                            </div>
                            <div className="single-contact-info">
                                <div dangerouslySetInnerHTML={{ __html: data.content }} />
                                {FooterBottomData.social !== undefined ? (
                                    <div className="social pt-8">
                                        {FooterBottomData.social.map(({ icon, url, text }, index) => {
                                            return (
                                                <a key={index} href={url} target="_blank" rel="noreferrer" className={`${icon} hvr-pulse`}>
                                                    <span className="visually-hidden">{text}</span>
                                                </a>
                                            );
                                        })}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default ContactOne;
